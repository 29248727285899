<template>
  <div class="page-comment">
    <div class="content">
      <div class="page-title">评价订单</div>
      <div class="info-area">
        <div class="info-left">
          <div class="goods" v-for="(item, index) in detail.items" :key="index">
            <img class="goods-img" :src="$host+item.image_id" />
            <div class="goods-name">{{ item.name }}</div>
            <div class="goods-price">￥{{ item.price }}</div>
          </div>
          <div class="goods" style="border: none">
            <img class="goods-img" :src="detail.doctors_info ?detail.doctors_info.avatar:''" />
            <div class="doctor-name">
              医生姓名：<strong>{{
                detail.doctors_info ? detail.doctors_info.nickname : ""
              }}</strong>
            </div>
          </div>
        </div>
        <div class="info-right">
          <div class="line-item">
            <label class="line-label" for="rating-inline">商品评分:</label>
            <b-form-rating
              id="rating-inline"
              inline
              :value="goods_comment.star"
              no-border
              color="#fb776a"
            ></b-form-rating>
          </div>
          <div style="display: flex; margin: 10px 0">
            <label class="line-label">商品评价:</label>
            <b-textarea
              placeholder="请输入商品评价"
              maxlength="200"
              class="line-textarea"
              v-model="goods_comment.content"
            ></b-textarea>
          </div>
          <input type="file" style="display: none" ref="file" @change="getFile" />
          <div class="upload-area">
            <div class="file-grid" v-for="(file, i) in fileList" :key="i">
              <div class="item-deshad" @click="deleteIMG(i)">
                <b-icon icon="trash"></b-icon>
              </div>
              <img class="img" :src="$host + file" />
            </div>
            <div class="file-grid">
              <div class="item up" @click="clickFile">
                <b-icon icon="image-fill"></b-icon>
                上传文件
              </div>
            </div>
          </div>
          <div class="line-item">
            <label class="line-label" for="rating-inline-doctor">医生评分:</label>
            <b-form-rating
              id="rating-inline-doctor"
              inline
              :value="doctors_comment.star"
              no-border
              color="#fb776a"
            ></b-form-rating>
          </div>
          <div style="display: flex; margin: 10px 0">
            <label class="line-label">医生评价:</label>
            <b-textarea
              placeholder="请输入医生评价"
              maxlength="200"
              class="line-textarea"
              v-model="doctors_comment.content"
            ></b-textarea>
          </div>
          <input ref="doctor" type="file" style="display: none" @change="changeFile" />
          <div class="upload-area">
            <div class="file-grid" v-for="(file, i) in doctorFiles" :key="i">
              <div class="item-deshad" @click="deleteFile(i)">
                <b-icon icon="trash"></b-icon>
              </div>
              <img class="img" :src="$host + file" />
            </div>
            <div class="file-grid">
              <div class="item up" @click="clickFile2">
                <b-icon icon="image-fill"></b-icon>
                上传文件
              </div>
            </div>
          </div>
          <div class="submit-area">
            <b-button variant="primary" :disabled="loading" @click="submit">提交</b-button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { uploader } from "../../common/ossUploadfile.js";
export default {
  data() {
    return {
      fileList: [],
      doctorFiles: [],
      goods_comment: {
        star: 0,
        content: "",
        product_id: "",
        goods_id: "",
        resource: "",
      },
      doctors_comment: {
        doctors_id: "",
        hospital_id: "",
        content: "",
        resource: "",
        star: 0,
      },
      detail: {},
      loading: false
    };
  },
  mounted() {
    this.getDetail();
  },
  methods: {
    getFile() {
      const inputFile = this.$refs.file.files[0];
      uploader(inputFile)
        .then((res) => {
          this.fileList.push(res.url);
        })
        .catch(() => {
          this.$store.commit("showToast", "上传失败，请重试！");
        });
    },
    clickFile() {
      if (this.fileList.length > 5) {
        return this.$store.commit("showToast", "最多上传6张图片");
      }
      this.$refs.file.dispatchEvent(new MouseEvent("click"));
    },
    deleteIMG(i) {
      console.log(i);
      this.fileList.splice(i, 1);
    },
    clickFile2() {
      if (this.doctorFiles.length > 5) {
        return this.$store.commit("showToast", "最多上传6张图片");
      }
      this.$refs.doctor.dispatchEvent(new MouseEvent("click"));
    },
    changeFile() {
      const inputFile = this.$refs.doctor.files[0];
      uploader(inputFile)
        .then((res) => {
          this.doctorFiles.push(res.url);
        })
        .catch(() => {
          this.$store.commit("showToast", "上传失败，请重试！");
        });
    },
    deleteFile(i) {
      return this.doctorFiles.splice(i, 1);
    },
    getDetail() {
      this.$http
        .order_detail({
          order_bn: this.$route.query.order,
        })
        .then((res) => {
          if (res.code == 1) {
            this.detail = res.data;
          }
          console.log(res);
        });
    },
    submit() {
      if (this.goods_comment.content == "" || this.doctors_comment.content == "") {
        return this.$store.commit("showToast", "请输入评价");
      }
      this.loading = true
      this.goods_comment.product_id = this.detail.items[0].product_id;
      this.goods_comment.resource = this.fileList.join();
      this.goods_comment.goods_id = this.detail.items[0].goods_id;
      this.doctors_comment.doctors_id = this.detail.doctors_id;
      this.doctors_comment.hospital_id = this.detail.hospital_id;
      this.doctors_comment.resource = this.doctorFiles.join();
      let data = {
        goods_comment: this.goods_comment,
        doctors_comment: this.doctors_comment,
        order_id: this.detail.id,
      };
      this.$http.order_comment(data).then((res) => {
        this.loading = false
        if (res.code == 1) {
          this.$store.commit("showToast", "评价成功");
          setTimeout(() => {
            this.$router.back();
          }, 1500);
        } else {
          this.$store.commit("showToast", res.msg);
        }
      }).catch(() => {
        this.loading = false
      });
    },
  },
};
</script>
<style lang="scss" scoped>
@import "./order.scss";
</style>
